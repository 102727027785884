import { useCallback, useRef } from 'react';
import ReactGA from "react-ga4";
import { YMInitializer } from 'react-yandex-metrika';
import { SlArrowDown } from "react-icons/sl";
import { IoRocketOutline } from "react-icons/io5";
import { SiSpringsecurity } from "react-icons/si";
import { GiBilledCap } from "react-icons/gi";


if (process.env.REACT_APP_GA_TAG) {
  ReactGA.initialize([
    {
      trackingId: process.env.REACT_APP_GA_TAG
    },
    {
      trackingId: process.env.REACT_APP_GTAG_TAG
    },
    {
      trackingId: process.env.REACT_APP_ADS_GTAG_TAG
    },
  ]);
}


function App() {
  const secondSectionRef = useRef();
  const tarifSectionRef = useRef();

  const scrollToSecondSection = useCallback(() => {
    secondSectionRef.current.scrollIntoView({ behavior: 'smooth' });
  }, [secondSectionRef]);

  const scrollToTarifSection = useCallback(() => {
    tarifSectionRef.current.scrollIntoView({ behavior: 'smooth' });
  }, [tarifSectionRef]);

  const onClick = useCallback(() => {
    // send conversion to google ads
    ReactGA.gtag('event', 'conversion', { 'send_to': process.env.REACT_APP_ADS_SEND_TO, 'value': 1.0, 'currency': 'GBP' })
  }, []);

  return (
    <div>
      {process.env.REACT_APP_YM_TAG && <YMInitializer accounts={[process.env.REACT_APP_YM_TAG]} />}

      <div className="h-dvh bg-hero-pattern bg-cover bg-center flex justify-center">
        <div className="grid grid-cols-1 h-full md:gap-12 gap-10 place-content-center">
          <div className="w-full flex justify-center md:h-52 h-36">
            <img className="md:w-52 md:h-52 w-36 h-36" src="android-chrome-512x512.png" alt="Бот Квантум"></img>
          </div>
          <h1 className="md:text-4xl text-2xl font-semibold text-white text-center">
            Привет, Я бот Квантум!
          </h1 >
          <div>
            <p className="md:text-xl text-lg font-light text-white text-center">Настраиваю быстрый безлимитный VPN.</p>
            <p className="md:text-xl text-lg font-light text-white text-center">Первые 3 дня бесплатно.</p>
          </div>
          <div className="w-full hidden md:flex justify-center">
            <a href="tg://resolve?domain=QuantumVPNbot&start=p7H3d1" onClick={onClick} className="bg-sky-500 hover:bg-sky-700 text-white font-bold py-4 px-8 rounded-full animate-pulse text-center">
              Открыть бота в Telegram
            </a>
          </div>
          <div className="w-full md:hidden flex justify-center">
            <button onClick={scrollToSecondSection} className="bg-sky-500 hover:bg-sky-700 text-white font-bold py-4 px-8 rounded-full animate-pulse text-center">
              Узнать больше
            </button>
          </div>
          <div className="w-full md:flex hidden justify-center">
            <button onClick={scrollToSecondSection}>
              <SlArrowDown color="white" className="w-11 h-11 md:mt-16 animate-bounce" />
            </button>
          </div>
        </div>
      </div >
      <div ref={secondSectionRef} className="bg-slate-100 flex justify-center pt-8">
        <div className="grid grid-cols-1 h-full md:gap-12 gap-10 place-content-center p-4">
          <h1 className="md:text-4xl text-2xl text-center font-semibold" >
            Почему Квантум VPN?
          </h1 >
          <div className="grid md:gap-12 gap-8">
            <div className="flex flex-row">
              <div className="w-16 h-16">
                <IoRocketOutline className="w-16 h-16 text-sky-500" />
              </div>
              <div className="pl-4">
                <p className="md:text-xl text-md font-semibold mb-2">Скорость и безлимитный трафик</p>
                <p className="md:text-xl text-md font-light max-w-lg">Можно смотреть видео и вообще не отключать VPN.</p>
              </div>
            </div>
            <div className="flex flex-row">
              <div className="w-16 h-16">
                <SiSpringsecurity className="w-16 h-16 text-sky-500" />
              </div>
              <div className="pl-4">
                <p className="md:text-xl text-md font-semibold mb-2">Приватность и безопасность</p>
                <p className="md:text-xl text-md font-light max-w-lg">Мы не собираем никаких данных. Ваш трафик будет полностью зашифрован.</p>
              </div>
            </div>
            <div className="flex flex-row">
              <div className="w-16 h-16">
                <GiBilledCap className="w-16 h-16 text-sky-500" />
              </div>
              <div className="pl-4">
                <p className="md:text-xl text-md font-semibold mb-2">Мы маленькие и мы в Telegram</p>
                <p className="md:text-xl text-md font-light max-w-lg">Когда VPN больших компаний перестает работать - наш работает. Поэтому мы пользуемся им сами.</p>
              </div>
            </div>
          </div>
          <div className="w-full flex flex-col justify-center">
            <div className="flex justify-center">
              <a href="tg://resolve?domain=QuantumVPNbot&start=p7H3d2" onClick={onClick} className="bg-sky-500 hover:bg-sky-700 text-white font-bold py-4 px-8 rounded-full animate-pulse text-center">
                Открыть бота в Telegram
              </a>
            </div>
            <button onClick={scrollToTarifSection} className="pt-8 px-8 text-center underline">
              Тарифы
            </button>
          </div>
        </div>
      </div>
      <div className="border-l-[50vw] border-l-transparent border-t-[100px] border-t-slate-100 border-r-[50vw] border-r-transparent"></div>
      <div ref={tarifSectionRef} className="grid justify-center">
        <div className="grid grid-cols-1 max-w-4xl h-full md:gap-12 gap-10 place-content-center p-4">
          <h1 className="md:text-4xl text-2xl text-center font-semibold" >
            От 159₽/мес.
          </h1 >
          <div>
            <p className="md:text-xl text-lg font-light text-center">Первые 3 дня бесплатно без ввода карты</p>
          </div>
          <div className="grid md:grid-cols-3 gap-8 text-center p-4">
            <div className="flex flex-col bg-sky-500 p-4 rounded-2xl min-h-36 shadow">
              <p className="font-semibold text-slate-100 mb-4 pb-1 border-b border-sky-400">3 месяца</p>
              <h3 className="md:text-4xl text-2xl text-center font-semibold text-white mb-2" >
                159₽/мес.*
              </h3 >
              <div className="text-center text-xl font-semibold text-slate-100">
                <span className="">479₽</span>
                <span className="line-through pl-2">597₽</span>
              </div>
            </div>
            <div className="flex flex-col bg-sky-500 p-4 rounded-2xl min-h-36 shadow">
              <p className="font-semibold text-slate-100 mb-4 pb-1 border-b border-sky-400">2 месяца</p>
              <h3 className="md:text-4xl text-2xl text-center font-semibold text-white mb-2" >
                179₽/мес.
              </h3 >
              <div className="text-center text-xl font-semibold text-slate-100">
                <span className="">359₽</span>
              </div>
            </div>
            <div className="flex flex-col bg-sky-500 p-4 rounded-2xl min-h-36 shadow">
              <p className="font-semibold text-slate-100 mb-4 pb-1 border-b border-sky-400">1 месяц</p>
              <h3 className="md:text-4xl text-2xl text-center font-semibold text-white mb-2" >
                199₽/мес.
              </h3 >
            </div>
          </div>
          {/* </div> */}
          <p className="text-center">*цена после бесплатного периода</p>
          <div className="w-full flex justify-center">
            <a href="tg://resolve?domain=QuantumVPNbot&start=p7H3t1" onClick={onClick} className="bg-sky-500 hover:bg-sky-700 text-white font-bold py-4 px-8 rounded-full animate-pulse text-center">
              Купить через Telegram
            </a>
          </div>
        </div>
      </div >
      <div className="border-l-[100vw] border-l-slate-200 border-t-[100px] border-t-transparent"></div>
      <div className="flex flex-col md:p-24 p-8 py-24 bg-slate-200">
        <div className="flex justify-center">
          <div className="h-[1px] w-24 bg-slate-400"></div>
        </div>
        <p className="text-center font-light text-3xl md:p-24 py-16">
          Работает настолько быстро и стабильно, что VPN можно не отключать!
        </p>
        <div className="flex justify-center">
          <div className="h-[1px] w-24 bg-slate-400"></div>
        </div>
      </div>
      <div className="grid bg-footer-pattern h-80 place-content-center">
        <div>
          <img className="w-36 h-36" src="logo-white-transparent.png" alt="Logo Quantum VPN"></img>
          <p className="text-center text-white font-bold">© 2023</p>
        </div>
      </div>
    </div >
  );
}

export default App;
